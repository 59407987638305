import { useState } from "react";
import axios from "axios";
function Show(){
    const[res, setRes]=useState(null);
    if(res==null){

        axios.get('https://event-management-system-h9fo.onrender.com/showall',{

    }).then(res=>{
        console.log(res.data);
        setRes(res.data);

         })
    }

    return(
        <div>
            {JSON.stringify(res)}

        </div>
    );
} 
 export default Show;
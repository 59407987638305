import React from 'react';

const Details = () => {
    return(
        <div class="row row-cols-1 row-cols-md-3 g-4 containers">
           <div class="col">
               <div class="card h-100">
                    <img src="./images/mi1.jpg" class="card-img-top" />
                       <div class="card-body">
                          <h5 class="card-title">Classic Mandapam Decor</h5>
                          <p class="card-text">Authentic Sounth India Wedding</p>
                        </div>
                               <div class="card-footer">
                                  <small class="text-muted"><a href="Deta">click to check out the details</a></small>
                                 </div>
                 </div>
             </div>
        </div>    
    )
    }
export default Details   